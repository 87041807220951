// ** MUI Imports
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

const FooterContent = () => {
  // ** Var
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'end', justifyContent: 'space-between' }}>
      {hidden ? null : (
        <>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', flexDirection: 'column' }}>
            <img
              height={`48px`}
              src={`${process.env.NEXT_PUBLIC_BASE_PATH}/images/logofooter.svg`}
              alt='Logo Bankaltimtara'
            />
            <Typography variant='caption' textAlign='center' sx={{ fontSize: '0.4rem', fontStyle: 'italic' }}>
              {`version ${process.env.NEXT_PUBLIC_APP_VERSION}`}
            </Typography>
          </Box>
        </>
      )}
      <Typography variant='subtitle2' textAlign='center' sx={{ mr: 2, mb: '0.4rem' }}>
        {`©${new Date().getFullYear()} • Powered by Divisi Human Capital `}
        <Link target='_blank' href='https://bankaltimtara.co.id/'>
          PT BPD Kaltim Kaltara
        </Link>
      </Typography>
    </Box>
  )
}

export default FooterContent
