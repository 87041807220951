// ** MUI Imports
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

// ** Components
import Link from 'src/components/Link'

// ** Next Imports
import { useRouter } from 'next/router'

const StyledLink = styled(Link)(({ theme }) => ({
  height: 72,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0px 5px',
  fontSize: '0.8rem',
  textAlign: 'center',
  color: theme.palette.text.primary
}))

const NavData = [
  {
    title: 'Beranda',
    Path: '/home'
  },
  {
    title: 'Rekrutmen',
    Path: '/rekrutmen'
  },

  // {
  //   title: 'Pengumuman',
  //   Path: '/pengumuman'
  // },
  {
    title: 'Tentang Kami',
    Path: '/aboutus'
  }
]

interface Props {
  hidden: boolean
}

const NavigationMenu = (props: Props) => {
  // ** Hook & Vars
  const { hidden } = props
  const router = useRouter()

  const isNavLinkActive = (path: string) => {
    // if (router.pathname === path) {
    if (router.pathname.includes(path)) {
      return true
    } else {
      return false
    }
  }

  return !hidden ? (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, cursor: 'pointer', height: 70 }}
    >
      {NavData.map(Element => {
        return (
          <StyledLink
            href={Element.Path}
            key={Element.Path}
            sx={{
              '&:hover': {
                textDecoration: 'none',
                borderBottomColor: theme => theme.palette.secondary.main,
                borderBottomWidth: '6px',
                borderBottomStyle: 'solid',
                marginBottom: '-6px'
              },
              ...(isNavLinkActive(Element.Path)
                ? {
                    color: theme => theme.palette.primary.main,
                    fontWeight: 600,
                    borderBottomColor: theme => theme.palette.secondary.main,
                    borderBottomWidth: '6px',
                    borderBottomStyle: 'solid',
                    marginBottom: '-6px'
                  }
                : null)
            }}
          >
            {Element.title}
          </StyledLink>
        )
      })}
    </Box>
  ) : null
}

export default NavigationMenu
