// ** Next Import
import Link from 'next/link'

// ** MUI Imports
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// **AppBar Import
import AppBarContent from './AppBarContent'

// **NavMenu Import
import Navigation from './NavigationMenu'

const StyledLink = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  marginRight: theme.spacing(8),
}))

const StyledImg = styled('img')(() => ({
  height: 30,
}))

const BlankLayoutAppBar = () => {

  // ** Hooks
  const theme = useTheme()
  const hiddenMd = useMediaQuery(theme.breakpoints.down('md'))
  const hiddenSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <AppBar elevation={1} position='sticky' sx={{ backgroundColor: theme => theme.myColor?.appBar }}>
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: theme => `${theme.spacing(0, 4)} !important`,
          minHeight: theme => `${theme.mixins.toolbar.minHeight}px !important`
        }}
      >
        <Link href='/home' passHref>
          <StyledLink>
            {!hiddenMd
              ? <StyledImg src={`${process.env.NEXT_PUBLIC_BASE_PATH}/images/logo/logo-tittle-ver.svg`} alt='erecrutment Bankaltimtara' />
              : <StyledImg src={`${process.env.NEXT_PUBLIC_BASE_PATH}/images/logo/logo.svg`} alt='erecrutment Bankaltimtara' />
            }
          </StyledLink>
        </Link>
        <Navigation hidden={hiddenSm} />
        <AppBarContent hiddenSm={hiddenSm} hiddenMd={hiddenMd} />
      </Toolbar>
    </AppBar>
  )
}

export default BlankLayoutAppBar
