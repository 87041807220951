// ** React Imports
import { useState, SyntheticEvent, Fragment } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** MUI Imports
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'

// ** Icons Imports
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import WorkRoundedIcon from '@mui/icons-material/WorkRounded'
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded'

// import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'

// ** Custom Components Imports

// ** Context

// ** Type Imports
import { Settings } from 'src/@core/context/settingsContext'

interface Props {
  settings: Settings
}

const NavMobile = (props: Props) => {
  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  // ** Hooks
  const router = useRouter()

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
    }
  }

  return (
    <Fragment>
      <IconButton size='small' color={settings.mode === 'dark' ? 'secondary' : 'primary'} aria-haspopup='true' onClick={handleDropdownOpen}
        sx={{
          width: 40, height: 40,
          backgroundColor: theme => theme.myColor?.colorToggleLight,
        }}>
        <MenuRoundedIcon fontSize='medium' />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 200, mt: 4, } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/home')}>
          <Box sx={styles}>
            <HomeRoundedIcon sx={{ mr: 4, color: theme => theme.myColor?.colorToggle }} />
            Beranda
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/rekrutmen')}>
          <Box sx={styles}>
            <WorkRoundedIcon sx={{ mr: 4, color: theme => theme.myColor?.colorToggle }} />
            Rekrutmen
          </Box>
        </MenuItem>
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pengumuman')}>
          <Box sx={styles}>
            <CampaignRoundedIcon sx={{ mr: 4, color: theme => theme.myColor?.colorToggle }} />
            Pengumuman
          </Box>
        </MenuItem> */}
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/aboutus')}>
          <Box sx={styles}>
            <ApartmentRoundedIcon sx={{ mr: 4, color: theme => theme.myColor?.colorToggle }} />
            Tentang Kami
          </Box>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default NavMobile
