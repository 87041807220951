import { useContext } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

// ** Next Import
import Link from 'next/link'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Components
import ModeToggler from 'src/layouts/components/shared/ModeToggler'
import AuthDropdown from 'src/layouts/components/shared/AuthDropdown'
import NavMobile from 'src/layouts/components/shared/NavMobile'
import UserDropdown from 'src/layouts/components/UserDropdown'

// import NotificationDropdown from 'src/layouts/components/shared/NotificationDropdown'

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings'
import { AuthContext } from 'src/context/AuthContext'

interface Props {
  hiddenSm: boolean
  hiddenMd: boolean
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { settings, saveSettings } = useSettings()
  const { hiddenSm, hiddenMd } = props

  // ** Hook & Vars
  const router = useRouter()

  const auth = useContext(AuthContext)

  return (
    <Stack spacing={4} direction='row'>
      <ModeToggler settings={settings} saveSettings={saveSettings} hidden={hiddenMd} />
      {
        auth.user
          ? <>
            {/* <NotificationDropdown /> */}
            <UserDropdown settings={settings} />
          </>
          : !hiddenMd
            ?
            <>
              <Link href='/login'>
                <Button variant='outlined' size='medium' color={router.pathname.includes('/login') ? 'secondary' : 'primary'}
                  sx={{
                    mr: 2, ml: 2, borderRadius: 3, borderWidth: 2, borderColor: theme => router.pathname.includes('/login') ? theme.palette.secondary.main : theme.palette.primary.main,
                    boxShadow: 5, textTransform: 'capitalize', fontWeight: 600,
                    '&:hover': { borderWidth: 2, }
                  }}>
                  Login
                </Button>
              </Link>
              <Link href='/register'>
                <Button variant='contained' size='medium' color={router.pathname.includes('/register') ? 'secondary' : 'primary'}
                  sx={{
                    borderRadius: 3, boxShadow: 5, textTransform: 'capitalize', fontWeight: 600
                  }}>
                  Register
                </Button>
              </Link>
            </>
            :
            <AuthDropdown settings={settings} />
      }
      {hiddenSm && <NavMobile settings={settings} />}
    </Stack>
  )
}

export default AppBarContent
