// ** React Imports
import { useState, SyntheticEvent, Fragment } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** MUI Imports
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'

// ** Icons Imports
import LoginRoundedIcon from '@mui/icons-material/LoginRounded'
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded'
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded'

// ** Custom Components Imports

// ** Context
// import { AuthContext } from 'src/context/AuthContext'

// ** Type Imports
import { Settings } from 'src/@core/context/settingsContext'

interface Props {
  settings: Settings
}

const AuthDropdown = (props: Props) => {
  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  // ** Hooks
  const router = useRouter()

  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
    }
  }

  return (
    <Fragment>
      <IconButton sx={{
        width: 40, height: 40,
        backgroundColor: theme => theme.myColor?.colorToggleLight,
      }}
        color={settings.mode === 'dark' ? 'secondary' : 'primary'} aria-haspopup='true' onClick={handleDropdownOpen}>
        <LoginRoundedIcon fontSize='medium' sx={{ mr: 1, }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/login')}>
          <Box sx={styles}>
            <LockOpenRoundedIcon sx={{ mr: 2, color: theme => theme.myColor?.colorToggle }} />
            Login
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/register')}>
          <Box sx={styles}>
            <VpnKeyRoundedIcon sx={{ mr: 2, color: theme => theme.myColor?.colorToggle }} />
            Register
          </Box>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default AuthDropdown
